import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProfileList from './ProfileList';
import ProfileCreate from './ProfileCreate';
import AccountInfo from './AccountInfo';
import Metrics from './Metrics';
import Overview from './Overview';
import ProfileInfo from './ProfileInfo';
import AccountSetting from './Setting';
import Goals from './Goals';
import Program from './Program';
import Testing from './Testing';
import Report from './Report';
import UserList from './User/UserList';
import UserCreate from './User/UserCreate';
import GroupList from './Group/GroupList';
import GroupCreate from './Group/GroupCreate';
import MetricsList from './MetricsList';
import GoalsList from './GoalsList';
import Biling from './UpgradePlan';
import Cancel from './Cancel';
import СRMConnect from './CRM/Connect';
import СRMGroup from './CRM/Group';
import CRMUser from './CRM/User';
import CRMEvent from './CRM/Event';
import CRMProfile from './CRM/Profile';
import Files from './Files';
import Notes from './Notes/Notes';
import ProfileTasks from './ProfileTasks';
import ProfileCalendar from './ProfileCalendar';
import ReportsList from './Analytics/ReportsList/ReportsList';
import CreateReport from './Analytics/CreateReport/CreateReport';
import { LayoutSplashScreen } from '../../../_metronic';
import { FormsList } from './Forms/FormsList/FormsList';
import { CreateForm } from './Forms/CreateForm/CreateForm';
import { Dashboard } from './Dashboard/Dashboard';
import { TaskList } from './Tasks/TaskList';
import { Calendar } from './Calendar/Calendar';
import { ProgramsList } from './Programs/ProgramsList/ProgramsList';
import { WorkoutsList } from './Workouts/WorkoutsList/WorkoutsList';
import { ExercisesList } from './Exercises/ExercisesList/ExercisesList';
import { CreateEditProgram } from './Programs/CreateEditProgram/CreateEditProgram';
import { CreateEditWorkout } from './Workouts/CreateEditWorkout/CreateEditWorkout';
import { CreateExercise } from './Exercises/CreateExercise/CreateExercise';
import { EditCreateWorkout } from './ProgramTabs/EditCreateWorkout/EditCreateWorkout';

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/profile-list" />

        <Route path="/dashboard/main" component={Dashboard} />
        <Route path="/dashboard/tasks" component={TaskList} />
        <Route path="/dashboard/calendar" component={Calendar} />

        <Route path="/account/info" component={AccountInfo} />
        <Route path="/account/setting" component={AccountSetting} />
        <Route path="/account/biling" component={Biling} />
        <Route path="/account/cancel" component={Cancel} />

        <Route path="/account/crm/connect" component={СRMConnect} />
        <Route path="/account/crm/group" component={СRMGroup} />
        <Route path="/account/crm/user" component={CRMUser} />
        <Route path="/account/crm/profile" component={CRMProfile} />
        <Route path="/account/crm/event" component={CRMEvent} />

        <Route path="/library/programs/list" component={ProgramsList} />
        <Route
          path="/library/programs/create"
          exact
          component={CreateEditProgram}
        />
        <Route path="/library/programs/:id" component={CreateEditProgram} />

        <Route path="/library/workouts/list" component={WorkoutsList} />
        <Route
          path="/library/workouts/create"
          exact
          component={CreateEditWorkout}
        />
        <Route path="/library/workouts/:id" component={CreateEditWorkout} />

        <Route path="/library/exercises/list" component={ExercisesList} />
        <Route
          path="/library/exercises/create"
          exact
          component={CreateExercise}
        />

        <Route path="/library/forms/list" component={FormsList} />
        <Route path="/library/forms/create" exact component={CreateForm} />
        <Route path="/library/forms/:id" component={CreateForm} />

        <Route path="/profile-list" component={ProfileList} />
        <Route path="/profile-create" component={ProfileCreate} />

        <Route path="/user-list" component={UserList} />
        <Route path="/user-create" component={UserCreate} />

        <Redirect exact from="/analytics" to="/analytics/reports-list" />
        <Route path="/analytics/reports-list" component={ReportsList} />
        <Route path="/analytics/new_report/:id" component={CreateReport} />
        <Route path="/analytics/report/:id" component={CreateReport} />

        <Route path="/group-list" component={GroupList} />
        <Route path="/group-create" component={GroupCreate} />

        <Route path="/profile/:id/info" component={ProfileInfo} />

        <Route path="/profile/:id/metrics" component={Metrics} />
        <Route path="/profile/:id/overview" component={Overview} />
        <Route path="/profile/:id/goals" component={Goals} />
        <Route path="/profile/:id/program" component={Program} />
        <Route path="/profile/:id/notes" component={Notes} />
        <Route path="/profile/:id/tasks" component={ProfileTasks} />
        <Route path="/profile/:id/calendar" component={ProfileCalendar} />
        <Route path="/profile/:id/testing" component={Testing} />
        <Route path="/profile/:id/report" component={Report} />
        <Route path="/profile/:id/Files" component={Files} />
        <Route
          path="/profile/:profileId/workouts/create"
          component={EditCreateWorkout}
        />
        <Route
          path="/profile/:profileId/workouts/:id"
          component={EditCreateWorkout}
        />

        <Route path="/profile/:id/metrics-list" component={MetricsList} />
        <Route path="/profile/:id/goals-list" component={GoalsList} />

        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
