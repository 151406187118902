import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Loader from '../../../../components/Loader';
import { TextField } from '@material-ui/core';
import DatePicker from '../../../../components/DatePicker';
import DropdownComponent from '../../../../components/DropdownComponent';
import SimpleDropdown from '../../../../components/SimpleDropdown';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as AlertState from '../../../../store/ducks/auth.duck';
import Dropdown from '../../../../components/DropdownMultiselectv2';
import { getUsersV2 } from '../../../../crud/info.crud';
import catchErrorMessage from '../../../../helpers/errorCatcher';
import { getProfileList } from '../../../../crud/profile.crud';
import { getMetric } from '../../../../crud/info.crud';
import { fetchWorkouts } from '../../../../crud/library.crud';
import {
  getSingleEvent,
  getSingleEventForProfile,
} from '../../../../crud/dashboard.crud';
import { handleSubmit } from './submitFunction';
import * as Yup from 'yup';
import moment from 'moment';
import './create-modal.scss';

const timeArray = [
  '12:00 am',
  '12:15 am',
  '12:30 am',
  '12:45 am',

  '01:00 am',
  '01:15 am',
  '01:30 am',
  '01:45 am',
  '02:00 am',
  '02:15 am',
  '02:30 am',
  '02:45 am',
  '03:00 am',
  '03:15 am',
  '03:30 am',
  '03:45 am',
  '04:00 am',
  '04:15 am',
  '04:30 am',
  '04:45 am',
  '05:00 am',
  '05:15 am',
  '05:30 am',
  '05:45 am',
  '06:00 am',
  '06:15 am',
  '06:30 am',
  '06:45 am',
  '07:00 am',
  '07:15 am',
  '07:30 am',
  '07:45 am',
  '08:00 am',
  '08:15 am',
  '08:30 am',
  '08:45 am',
  '09:00 am',
  '09:15 am',
  '09:30 am',
  '09:45 am',
  '10:00 am',
  '10:15 am',
  '10:30 am',
  '10:45 am',
  '11:00 am',
  '11:15 am',
  '11:30 am',
  '11:45 am',

  '12:00 pm',
  '12:15 pm',
  '12:30 pm',
  '12:45 pm',
  '01:00 pm',
  '01:15 pm',
  '01:30 pm',
  '01:45 pm',
  '02:00 pm',
  '02:15 pm',
  '02:30 pm',
  '02:45 pm',
  '03:00 pm',
  '03:15 pm',
  '03:30 pm',
  '03:45 pm',
  '04:00 pm',
  '04:15 pm',
  '04:30 pm',
  '04:45 pm',
  '05:00 pm',
  '05:15 pm',
  '05:30 pm',
  '05:45 pm',
  '06:00 pm',
  '06:15 pm',
  '06:30 pm',
  '06:45 pm',
  '07:00 pm',
  '07:15 pm',
  '07:30 pm',
  '07:45 pm',
  '08:00 pm',
  '08:15 pm',
  '08:30 pm',
  '08:45 pm',
  '09:00 pm',
  '09:15 pm',
  '09:30 pm',
  '09:45 pm',
  '10:00 pm',
  '10:15 pm',
  '10:30 pm',
  '10:45 pm',
  '11:00 pm',
  '11:15 pm',
  '11:30 pm',
  '11:45 pm',
];

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'Too short - should be at least 2 characters')
    .max(100, 'Too long - should not exceed 100 characters')
    .required('Required'),
  date: Yup.date()
    .typeError('Invalid date')
    .required('Required'),
  start_time: Yup.string()
    .required('Required')
    .nullable('Required'),
  end_time: Yup.string()
    .required('Required')
    .nullable('Required'),
  users: Yup.array()
    .required('Required')
    .min(1, 'Required'),
});

const getStartTime = () => {
  let minutes = moment().minutes();
  if (minutes < 15) {
    return moment().format('h') + ':00 ' + moment().format('a');
  }
  if (minutes < 30) {
    return moment().format('h') + ':15 ' + moment().format('a');
  }
  if (minutes < 45) {
    return moment().format('h') + ':30 ' + moment().format('a');
  }
  if (minutes < 60) {
    return moment().format('h') + ':45 ' + moment().format('a');
  }
};

const getEndTime = (startTime) => {
  return moment(startTime, 'h:mm a')
    .add(1, 'hour')
    .format('h:mm a');
};

export const CreateModal = ({
  modal,
  setModal,
  selectedEventId,
  setSelectedEventId,
  loadEvents,
  profileId,
  currentDate,
}) => {
  const [loader, setLoader] = useState(false);
  const [userList, setUserList] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [metricsList, setMetricsList] = useState([]);
  const [workoutsList, setWorkoutsList] = useState([]);
  const accountId = useSelector((state) => state.appData.account.id);
  const role = useSelector((state) => state.auth.user.roles);
  const dispatch = useDispatch();

  const initValues = {
    title: '',
    type: '',
    description: '',
    date: moment(currentDate).format('MM/DD/YYYY'),
    workout: null,
    metrics: [],
    profiles: [],
    users: [],
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: ValidationSchema,
    validateOnBlur: true,
    onSubmit: () =>
      handleSubmit(
        formik,
        setLoader,
        setSelectedEventId,
        loadEvents,
        accountId,
        handleClose,
        selectedEventId,
        dispatch
      ),
  });

  useEffect(() => {
    if (selectedEventId) {
      setLoader(true);

      if (profileId && role === 'client') {
        getSingleEventForProfile(profileId, selectedEventId)
          .then((res) => {
            const profiles = res.data.single.profiles.map((elem) => ({
              ...elem,
              title: elem.first_name + ' ' + elem.last_name,
              value: { profile_id: elem.id },
            }));
            const users = res.data.single.users.map((elem) => ({
              ...elem,
              title: elem.first_name + ' ' + elem.last_name,
            }));
            const metrics = res.data.single.metrics.map((elem) => ({
              ...elem,
              title: elem.metric.title,
              id: elem.metric.id,
            }));
            const date = moment(res.data.single.start_at).format('MM/DD/YYYY');
            const start_time = moment(res.data.single.start_at).format(
              'hh:mm a'
            );
            const end_time = moment(res.data.single.end_at).format('hh:mm a');
            formik.setValues({
              ...res.data.single,
              date,
              start_time,
              end_time,
              profiles,
              metrics,
              users,
            });
          })
          .finally(() => setLoader(false));
      } else {
        getSingleEvent(accountId, selectedEventId)
          .then((res) => {
            const profiles = res.data.single.profiles.map((elem) => ({
              ...elem,
              title: elem.first_name + ' ' + elem.last_name,
              value: { profile_id: elem.id },
            }));
            const users = res.data.single.users.map((elem) => ({
              ...elem,
              title: elem.first_name + ' ' + elem.last_name,
            }));
            const metrics = res.data.single.metrics.map((elem) => ({
              ...elem,
              title: elem.metric.title,
              id: elem.metric.id,
            }));
            const date = moment(res.data.single.start_at).format('MM/DD/YYYY');
            const start_time = moment(res.data.single.start_at).format(
              'hh:mm a'
            );
            const end_time = moment(res.data.single.end_at).format('hh:mm a');
            formik.setValues({
              ...res.data.single,
              date,
              start_time,
              end_time,
              profiles,
              metrics,
              users,
            });
          })
          .finally(() => setLoader(false));
      }
    }
  }, [selectedEventId, role]);

  useEffect(() => {
    if (modal && role !== 'client') {
      getUsersV2()
        .then((res) => {
          const list = res.data?.list.map((elem) => ({
            ...elem,
            title: `${elem.first_name} ${elem.last_name}`,
          }));
          const sorted = list.sort((a, b) => {
            let first = a.title.toLowerCase();
            let second = b.title.toLowerCase();
            if (first < second) return -1;
            if (first > second) return 1;
            return 0;
          });
          setUserList(sorted);
        })
        .catch((err) => {
          let errText = catchErrorMessage(err);
          dispatch(
            AlertState.actions.alert({
              text: errText,
              variant: false,
            })
          );
        });
    }
  }, [modal, role]);

  useEffect(() => {
    if (modal && role !== 'client') {
      getProfileList().then((res) => {
        const newArr = [];
        res.data.data.profiles.forEach((elem) => {
          const profile = {
            title: `${elem.first_name} ${elem.last_name}`,
            value: elem,
          };
          newArr.push(profile);
        });
        const arr = newArr.sort((a, b) => {
          let nameA = a.title.toLowerCase();
          let nameB = b.title.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
        setProfileList(arr);
        if (profileId) {
          const currentProfile = arr.find(
            (elem) => elem.value.profile_id + '' === profileId + ''
          );
          formik.setFieldValue('profiles', [
            {
              title:
                currentProfile.value.first_name +
                ' ' +
                currentProfile.value.last_name,
              value: currentProfile.value,
            },
          ]);
        }
      });
    }
  }, [modal, role]);

  useEffect(() => {
    if (modal && role !== 'client') {
      getMetric().then((res) => {
        setMetricsList(
          res.data.data.map((elem) => ({ ...elem, title: elem.label }))
        );
      });
    }
  }, [modal, role]);

  useEffect(() => {
    if (modal && role !== 'client') {
      fetchWorkouts({
        perPage: 1000,
      }).then((res) => {
        setWorkoutsList(res.data.workouts);
      });
    }
  }, [modal, role]);

  useEffect(() => {
    let startTime = getStartTime();
    formik.handleChange({ target: { value: startTime, name: 'start_time' } });
    formik.handleChange({
      target: { value: getEndTime(startTime), name: 'end_time' },
    });
  }, [modal]);

  const handleClose = () => {
    setModal(false);
    setSelectedEventId('');
    formik.resetForm();
  };

  const onDateChange = (value, name) => {
    let val = value;
    if (value === '  /  /    ') val = null;
    formik.handleChange({ target: { value: val, name } });
  };

  const handleStartTimeChange = (startTime) => {
    formik.handleChange({ target: { value: startTime, name: 'start_time' } });
    formik.handleChange({
      target: { value: getEndTime(startTime), name: 'end_time' },
    });
  };

  const getTimeArray = () => {
    if (!formik.values.start_time) return [];
    if (formik.values.start_time) {
      const index = timeArray.findIndex(
        (elem) => elem === formik.values.start_time
      );
      let arr = [...timeArray];
      arr.splice(0, index + 1);
      return arr;
    }
    return timeArray;
  };

  const getTimeArray2 = () => {
    if (formik.values.end_time) {
      const index = timeArray.findIndex(
        (elem) => elem === formik.values.end_time
      );
      let arr = [...timeArray];
      arr.splice(index, arr.length - index);
      return arr;
    }
    return timeArray;
  };

  return (
    <Modal show={modal !== false} onHide={handleClose}>
      <div className="event-modal">
        <Loader visible={loader} />
        <div className="close-button" onClick={handleClose}>
          ×
        </div>
        <Modal.Title>
          <h5 className="title">
            {selectedEventId ? 'View Event' : 'Create Event'}
          </h5>
        </Modal.Title>
        <Modal.Body>
          <div className="row">
            <TextField
              name="title"
              style={{ width: '100%' }}
              value={formik.values.title}
              variant="outlined"
              type="text"
              label="Event Name"
              inputProps={{ maxLength: 100 }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!(formik.touched.title && formik.errors.title)}
              helperText={!!formik.touched.title && formik.errors.title}
              disabled={role === 'client'}
            />
          </div>
          <div className="row">
            <div className="row__elem-picker">
              <DatePicker
                style={{ width: '100%' }}
                touchPicker={formik.errors.start_at && true}
                name="date"
                label="Date"
                future={true}
                format={'MM/DD/YYYY'}
                value={formik.values.date}
                onChange={(value) => onDateChange(value, 'date')}
                onBlur={() => formik.handleBlur({ target: { name: 'date' } })}
                error={formik.touched.date && !!formik.errors.date}
                helperText={formik.touched.date && formik.errors.date}
                disabled={role === 'client'}
              />
            </div>
          </div>
          <div className="row">
            <div className="row__elem">
              <SimpleDropdown
                width="160px"
                label="Start Time"
                options={getTimeArray2()}
                value={formik.values.start_time}
                onChange={(value) => handleStartTimeChange(value)}
                error={formik.touched.start_time && !!formik.errors.start_time}
                helperText={
                  formik.touched.start_time && formik.errors.start_time
                }
                onBlur={() =>
                  formik.handleBlur({ target: { name: 'start_time' } })
                }
                disabled={role === 'client'}
              />
            </div>

            <div className="row__elem">
              <SimpleDropdown
                width="160px"
                label="End Time"
                options={getTimeArray()}
                value={formik.values.end_time}
                onChange={(value) =>
                  formik.handleChange({ target: { value, name: 'end_time' } })
                }
                onBlur={() =>
                  formik.handleBlur({ target: { name: 'end_time' } })
                }
                error={formik.touched.end_time && !!formik.errors.end_time}
                helperText={formik.touched.end_time && formik.errors.end_time}
                disabled={
                  (!formik.values.start_time && true) || role === 'client'
                }
              />
            </div>
          </div>
          <div className="row" style={{ position: 'relative', zIndex: '5' }}>
            <Dropdown
              style={{ width: '100%' }}
              onChange={(value) =>
                formik.handleChange({ target: { value, name: 'users' } })
              }
              value={formik.values.users}
              options={userList}
              label="User(s)"
              error={formik.touched.users && !!formik.errors.users}
              onBlur={formik.handleBlur}
              helperText={formik.touched.users && formik.errors.users}
              disabled={role === 'client'}
            />
          </div>
          <div className="row" style={{ position: 'relative', zIndex: '4' }}>
            <Dropdown
              style={{ width: '100%' }}
              onChange={(value) =>
                formik.handleChange({ target: { value, name: 'profiles' } })
              }
              value={formik.values.profiles}
              options={profileList}
              label="Profile(s)"
              error={formik.touched.profiles && !!formik.errors.profiles}
              onBlur={formik.handleBlur}
              helperText={formik.touched.profiles && formik.errors.profiles}
              disabled={role === 'client' || profileId}
            />
          </div>
          <div className="row" style={{ position: 'relative', zIndex: '3' }}>
            <Dropdown
              style={{ width: '100%' }}
              onChange={(value) =>
                formik.handleChange({ target: { value, name: 'metrics' } })
              }
              value={formik.values.metrics}
              options={metricsList}
              label="Metric(s)"
              error={formik.touched.metrics && !!formik.errors.metrics}
              onBlur={formik.handleBlur}
              helperText={formik.touched.metrics && formik.errors.metrics}
              disabled={role === 'client'}
            />
          </div>
          <div className="row" style={{ position: 'relative', zIndex: '2' }}>
            <DropdownComponent
              style={{ width: '100%' }}
              setFormData={(value) =>
                formik.handleChange({ target: { value, name: 'workout' } })
              }
              value={formik.values.workout}
              options={workoutsList}
              label="Workout"
              error={formik.touched.workout && !!formik.errors.workout}
              onBlur={formik.handleBlur}
              helperText={formik.touched.workout && formik.errors.workout}
              disabled={role === 'client'}
            />
          </div>
          <div className="row">
            <TextField
              multiline
              rows="2"
              name="description"
              style={{ width: '100%' }}
              label="Event Note"
              value={formik.values.description}
              inputProps={{
                maxLength: 1000,
              }}
              onChange={formik.handleChange}
              variant="outlined"
              error={formik.touched.description && !!formik.errors.description}
              helperText={
                formik.touched.description && formik.errors.description
              }
              onBlur={formik.handleBlur}
              disabled={role === 'client'}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {role !== 'client' && (
            <Button
              variant="primary"
              className="btn-blue"
              onClick={formik.handleSubmit}
            >
              Save
            </Button>
          )}
        </Modal.Footer>
      </div>
    </Modal>
  );
};
