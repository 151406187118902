import { Portlet, PortletBody } from '../../../partials/content/Portlet';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as AlertState from '../../../store/ducks/auth.duck';
import { Redirect } from 'react-router-dom';
import Loader from '../../../components/Loader';
import styled from 'styled-components';
import { Button, Pagination, Modal } from 'react-bootstrap';
import {
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    TextField,
    ListItemText,
    OutlinedInput,
    FormHelperText,
    Typography,
    Tab,
    Tabs,
} from '@material-ui/core';
import { Formik } from 'formik';
import TableList from './TableUserList';
import {
    getAllUsers,
    deleteUsers,
    updateUsers,
    getUserById,
    getAllGroup,
    massAttachGroups,
} from '../../../crud/info.crud';
import Logout from '../../auth/Logout';
import moment from 'moment';
import ModalCreateFromCRM from './ModalCreateFromCrm';
import '../table.scss';
import '../style.scss';

export default (props) => {
    document.title = 'TrainerMetrics - Users List';
    const crm = useSelector((state) =>
        state.user_info.CRM_list.find((elem) => elem.is_activated === true)
    );
    const plan = useSelector(
        (state) => state.user_info.subscription?.plan?.name
    );
    const role = useSelector((state) => state.auth?.user?.roles);
    const dispatch = useDispatch();
    const returnQuery = (label) => {
        let query = props.location.search.slice(1).split('=');
        const index = query.findIndex((item) => item === label);
        return query[index + 1].toString() || '';
    };
    const [logout, setLogout] = useState(null);
    const [data, setData] = useState([]);
    const [show, setShow] = useState('10');
    const [page, setPage] = useState(false);
    const [pagination, setPagination] = useState(1);
    const [check, setCheck] = useState([]);
    const [checkGroups, setCheckGroups] = useState([]);
    const [errors, setErrors] = useState({});
    const [deleteModal, setDeleteModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [userType, setUserType] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [groupListData, setGroupListData] = useState('');
    const [updateInitial, setUpdateInitial] = useState({
        id: '',
        email: '',
        first_name: '',
        lasst_name: '',
        // username: '',
        type: '',
        password: '',
    });
    const [type, setType] = useState([]);
    const [typeKey, setTypeKey] = useState(0);
    const [loaderAllGroups, setLoaderAllGroups] = useState(true);
    const [loaderUser, setLoaderUser] = useState(false);
    const [loaderDeleteUsers, setLoaderDeleteUsers] = useState(false);
    const [loaderUserById, setLoaderUserById] = useState(false);
    const [loaderAddGroups, setLoaderAddGroups] = useState(false);
    const [loaderUpdateUser, setLoaderUpdateUser] = useState(false);
    const [loader, setLoader] = useState(false);
    const [sort, setSort] = useState(['name', 'asc']);
    const [dropdown, setDropdown] = useState(false);
    const [modalCreateFromCRM, setModalCreateFromCRM] = useState(false);

    useEffect(() => {
        if (role === 'staff' || role === 'client') {
            dispatch(
                AlertState.actions.alert({
                    text: 'User does not have the right roles',
                    variant: false,
                })
            );
            setLogout(<Redirect to="/profile-list" />);
        }
    }, [role]);

    useEffect(() => {
        setLoaderAllGroups(true);
        getAllGroup()
            .then((res) => {
                setLoaderAllGroups(false);
                setGroupList(res.data.data);
                returnQuery('group') &&
                    setGroupListData(parseInt(returnQuery('group')));
            })
            .catch(({ response }) => {
                setGroupList(false);
                setLoaderAllGroups(false);
                if (response && response.status === 401) {
                    setLogout(<Logout />);
                }
            });
    }, []);

    useEffect(() => {
        setType(
            typeKey === 0
                ? ''
                : typeKey === 1
                ? 'admin'
                : typeKey === 2
                ? 'supervisor'
                : typeKey === 3
                ? 'staff'
                : ''
        );
    }, [typeKey]);

    useEffect(() => {
        loadData();
    }, [pagination, show]);

    useEffect(() => {
        setCheckGroups([]);
    }, [check]);

    const loadData = () => {
        setLoaderUser(true);
        getAllUsers()
            .then((res) => {
                setCheck([]);
                setLoaderUser(false);
                setData(res.data.data);
            })
            .catch(({ response }) => {
                response &&
                    response.data &&
                    dispatch(
                        AlertState.actions.alert({
                            text: response.data.message || response.data.error,
                            variant: false,
                        })
                    );
                setLoaderUser(false);
                if (response && response.status === 401) {
                    setLogout(<Logout />);
                } else if (response && response.status === 403) {
                    setLogout(<Redirect to="/profile-list" />);
                }
            });
    };

    const [search, setSearch] = useState('');
    const [filterData, setFilterData] = useState([]);

    useEffect(() => {
        filterDataF();
    }, [search, data, pagination, groupListData, type, sort]);

    useEffect(() => {
        setPagination(1);
    }, [show, search, groupListData]);

    const filterDataF = () => {
        const filter = data
            .filter((item) => {
                if (search.length < 2) return item;
                return (
                    item.email.toUpperCase().indexOf(search.toUpperCase()) !==
                        -1 ||
                    item.name.toUpperCase().indexOf(search.toUpperCase()) !==
                        -1 ||
                    item.type.toUpperCase().indexOf(search.toUpperCase()) !==
                        -1 ||
                    item.groups.toUpperCase().indexOf(search.toUpperCase()) !==
                        -1
                );
            })
            .filter((item) => {
                if (!type) return item;
                return item.type === type;
            })
            .filter((item) => {
                if (groupListData === '') return item;
                else if (groupListData === 'all_group_dev')
                    return !!item.groups_id.length;
                return item.groups_id.indexOf(groupListData) !== -1;
            });

        const sortFilter = [...filter].sort((a, b) => {
            if (
                sort[0] !== 'name' &&
                typeof a[sort[0]] === 'string' &&
                typeof b[sort[0]] === 'string'
            ) {
                if (a[sort[0]].toUpperCase() > b[sort[0]].toUpperCase()) {
                    return 1;
                }
                if (a[sort[0]].toUpperCase() < b[sort[0]].toUpperCase()) {
                    return -1;
                }
                return 0;
            } else if (
                sort[0] === 'name' &&
                typeof a.name === 'string' &&
                typeof b.name === 'string'
            ) {
                if (a.name.toUpperCase() > b.name.toUpperCase()) {
                    return 1;
                }
                if (a.name.toUpperCase() < b.name.toUpperCase()) {
                    return -1;
                }
                return 0;
            } else if (sort[0] === 'last_login') {
                return moment(a[sort[0]], 'MM/DD/YYYY').format('YYYYMMDD') >
                    moment(b[sort[0]], 'MM/DD/YYYY').format('YYYYMMDD')
                    ? 1
                    : -1;
            } else {
                return 0;
            }
        });

        const numPage = Math.floor(sortFilter.length / parseInt(show));

        setPage({
            last_page:
                numPage === sortFilter.length / parseInt(show)
                    ? numPage
                    : numPage + 1,
            per_page: parseInt(show),
            total: sortFilter.length,
        });

        setFilterData(
            sort[1] === 'asc'
                ? sortFilter.splice(
                      (pagination - 1) * parseInt(show),
                      parseInt(show)
                  )
                : sortFilter
                      .reverse()
                      .splice((pagination - 1) * parseInt(show), parseInt(show))
        );
    };

    const checkAll = () => {
        setCheck(() => data.map((item) => item.id));
    };

    const deleteUserAction = () => {
        setLoaderDeleteUsers(true);
        deleteUsers({
            users: check,
        })
            .then((res) => {
                setLoaderDeleteUsers(false);
                setCheck([]);
                loadData();
                setPagination(1);
                dispatch(
                    AlertState.actions.alert({
                        text: 'Success deleting',
                        variant: true,
                    })
                );
            })
            .catch(({ response }) => {
                response &&
                    response.data &&
                    dispatch(
                        AlertState.actions.alert({
                            text: response.data.message || response.data.error,
                            variant: false,
                        })
                    );
                setLoaderDeleteUsers(false);
                if (response && response.status === 401) {
                    setLogout(<Logout />);
                } else if (response && response.status === 403) {
                    setLogout(<Redirect to="/profile-list" />);
                }
            });
    };

    const UserById = () => {
        setLoaderUserById(true);
        getUserById(check[0])
            .then((res) => {
                setLoaderUserById(false);
                setUpdateInitial({
                    id: res.data.data.find((item) => item.name === 'id').value,
                    email: res.data.data.find((item) => item.name === 'email')
                        .value,
                    first_name: res.data.data.find(
                        (item) => item.name === 'first_name'
                    ).value,
                    last_name: res.data.data.find(
                        (item) => item.name === 'last_name'
                    ).value,
                    // username: res.data.data.find(item => item.name === 'username').value,
                    type: res.data.data.find((item) => item.name === 'type')
                        .value,
                    password: '',
                });
                setUserType(
                    res.data.data.find((item) => item.name === 'type').options
                );
            })
            .catch(({ response }) => {
                setLoaderUserById(false);
                if (response && response.status === 401) {
                    setLogout(<Logout />);
                } else if (response && response.status === 403) {
                    setLogout(<Redirect to="/profile-list" />);
                }
            });
    };

    const addGroups = () => {
        setLoaderAddGroups(true);
        massAttachGroups({
            users: check,
            groups: checkGroups,
        })
            .then((res) => {
                setLoaderAddGroups(false);
                loadData();
                dispatch(
                    AlertState.actions.alert({
                        text: 'Changes are saved',
                        variant: true,
                    })
                );
            })
            .catch(({ response }) => {
                response &&
                    response.data &&
                    dispatch(
                        AlertState.actions.alert({
                            text: response.data.message || response.data.error,
                            variant: false,
                        })
                    );
                setLoaderAddGroups(false);
                if (response && response.status === 401) {
                    setLogout(<Logout />);
                } else if (response && response.status === 403) {
                    setLogout(<Redirect to="/profile-list" />);
                }
            });
    };

    const user = useSelector(
        (store) => store.auth && store.auth.user && store.auth.user
    );

    const checkMe = () => {
        return check.findIndex((item) => item === user.id) === -1;
    };

    const [confirmAddModal, setConfirmAddModal] = useState(false);

    const inputLabel = useRef();
    const [labelWidth, setLabelWidth] = useState(0);

    useEffect(() => {
        inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel, updateModal]);

    const inputLabel_2 = useRef();
    const [labelWidth_2, setLabelWidth_2] = useState(0);

    useEffect(() => {
        inputLabel_2.current &&
            setLabelWidth_2(inputLabel_2.current.offsetWidth);
    }, [inputLabel_2, check]);

    const inputLabel_3 = useRef();
    const [labelWidth_3, setLabelWidth_3] = useState(0);

    useEffect(() => {
        inputLabel_3.current &&
            setLabelWidth_3(inputLabel_3.current.offsetWidth);
    }, [inputLabel_3, check]);

    const inputLabel_4 = useRef();
    const [labelWidth_4, setLabelWidth_4] = useState(0);

    useEffect(() => {
        inputLabel_4.current &&
            setLabelWidth_4(inputLabel_4.current.offsetWidth);
    }, [inputLabel_4, check]);

    const buttonRef = useRef();

    useEffect(() => {
        if (!crm) return;
        function clickOutside(e) {
            if (buttonRef && !buttonRef.current.contains(e.target)) {
                setDropdown(false);
            }
        }

        document.addEventListener('mousedown', clickOutside);
        return () => document.removeEventListener('mousedown', clickOutside);
    }, [buttonRef, crm]);

    return (
        <StyledGroupLists>
            {logout}
            <Loader
                visible={
                    loaderAllGroups ||
                    loaderUser ||
                    loaderDeleteUsers ||
                    loaderUserById ||
                    loaderAddGroups ||
                    loaderUpdateUser ||
                    loader
                }
            />

            <Modal
                show={confirmAddModal}
                onHide={(e) => {
                    setConfirmAddModal(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Are you sure you want to delete users from groups?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={(e) => {
                            setConfirmAddModal(false);
                        }}
                    >
                        No
                    </Button>
                    <Button
                        onClick={(e) => {
                            setConfirmAddModal(false);
                            addGroups();
                        }}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={deleteModal}
                onHide={(e) => {
                    setDeleteModal(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete checked users?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={(e) => {
                            setDeleteModal(false);
                        }}
                    >
                        No
                    </Button>
                    <Button
                        onClick={(e) => {
                            setDeleteModal(false);
                            deleteUserAction();
                        }}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={updateModal}
                onHide={(e) => {
                    setUpdateModal(false);
                }}
            >
                <Formik
                    initialValues={updateInitial}
                    enableReinitialize={true}
                    validate={(values) => {
                        const temp = {};

                        if (!values.first_name) {
                            temp.first_name = 'This value should not be blank';
                        } else if (values.first_name.length > 50) {
                            temp.first_name = 'Field is not valid';
                        } else if (values.first_name.length < 1) {
                            temp.first_name =
                                'The First Name should be at least 1 of characters';
                        }

                        if (!values.last_name) {
                            temp.last_name = 'This value should not be blank';
                        } else if (values.last_name.length > 50) {
                            temp.last_name = 'Field is not valid';
                        } else if (values.last_name.length < 1) {
                            temp.last_name =
                                'The Last Name should be at least 1 of characters';
                        }

                        if (!values.email) {
                            temp.email = 'This value should not be blank';
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                values.email
                            )
                        ) {
                            temp.email = 'Wrong value';
                        }

                        if (
                            values.password &&
                            (values.password.length > 32 ||
                                values.password.length < 8)
                        ) {
                            temp.password = 'Field is not valid';
                        }

                        setErrors(temp);
                        return temp;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        if (!values.password) {
                            delete values.password;
                        }
                        setLoaderUpdateUser(true);
                        updateUsers(values.id, values)
                            .then((res) => {
                                setLoaderUpdateUser(false);
                                setStatus(false);
                                setUpdateModal(false);
                                setCheck([]);
                                loadData();
                                dispatch(
                                    AlertState.actions.alert({
                                        text: 'User is updated',
                                        variant: true,
                                    })
                                );
                            })
                            .catch(({ response }) => {
                                response &&
                                    response.data &&
                                    dispatch(
                                        AlertState.actions.alert({
                                            text:
                                                response.data.message ||
                                                response.data.error,
                                            variant: false,
                                        })
                                    );
                                setLoaderUpdateUser(false);
                                if (response && response.status === 401) {
                                    setLogout(<Logout />);
                                } else if (
                                    response &&
                                    response.status === 403
                                ) {
                                    setLogout(<Redirect to="/profile-list" />);
                                }
                                !!response &&
                                    !!response.data &&
                                    !!response.data.errors &&
                                    setErrors({
                                        first_name:
                                            response.data.errors.first_name &&
                                            response.data.errors.first_name[0],
                                        last_name:
                                            response.data.errors.last_name &&
                                            response.data.errors.last_name[0],
                                        email:
                                            response.data.errors.email &&
                                            response.data.errors.email[0],
                                        password:
                                            response.data.errors.password &&
                                            response.data.errors.password[0],
                                        type:
                                            response.data.errors.type &&
                                            response.data.errors.type[0],
                                    });
                                setSubmitting(false);
                                setStatus(response.data.message);
                            });
                    }}
                >
                    {({
                        values,
                        status,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <form
                            noValidate={true}
                            autoComplete="off"
                            className="kt-form root"
                            onSubmit={handleSubmit}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Update User</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                {check.length > 1 ? (
                                    'Please select 1 user only.'
                                ) : check.length === 1 ? (
                                    <>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <TextField
                                                        key="54"
                                                        variant="outlined"
                                                        type="text"
                                                        label="Email"
                                                        margin="normal"
                                                        className="kt-width-full"
                                                        name="email"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={
                                                            values.email || ''
                                                        }
                                                        helperText={
                                                            touched.email &&
                                                            errors.email
                                                        }
                                                        error={Boolean(
                                                            touched.email &&
                                                                errors.email
                                                        )}
                                                    />

                                                    <TextField
                                                        key="55"
                                                        variant="outlined"
                                                        type="text"
                                                        label="First Name"
                                                        margin="normal"
                                                        className="kt-width-full"
                                                        name="first_name"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        inputProps={{
                                                            maxLength: 50,
                                                        }}
                                                        value={
                                                            values.first_name ||
                                                            ''
                                                        }
                                                        helperText={
                                                            touched.first_name &&
                                                            errors.first_name
                                                        }
                                                        error={Boolean(
                                                            touched.first_name &&
                                                                errors.first_name
                                                        )}
                                                    />

                                                    <TextField
                                                        key="56"
                                                        variant="outlined"
                                                        type="text"
                                                        label="Last Name"
                                                        margin="normal"
                                                        className="kt-width-full"
                                                        name="last_name"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        inputProps={{
                                                            maxLength: 50,
                                                        }}
                                                        value={
                                                            values.last_name ||
                                                            ''
                                                        }
                                                        helperText={
                                                            touched.last_name &&
                                                            errors.last_name
                                                        }
                                                        error={Boolean(
                                                            touched.last_name &&
                                                                errors.last_name
                                                        )}
                                                    />

                                                    <div className="MuiFormControl-root MuiTextField-root kt-width-full MuiFormControl-marginNormal">
                                                        <FormControl
                                                            variant="outlined"
                                                            className="formControl"
                                                        >
                                                            <InputLabel
                                                                ref={inputLabel}
                                                                htmlFor="select-multiple-checkbox"
                                                            >
                                                                Type
                                                            </InputLabel>
                                                            <Select
                                                                disabled={
                                                                    userType.length ===
                                                                        1 ||
                                                                    (!checkMe() &&
                                                                        user.roles ===
                                                                            'supervisor')
                                                                }
                                                                value={
                                                                    values.type
                                                                }
                                                                name="type"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                input={
                                                                    <OutlinedInput
                                                                        labelWidth={
                                                                            labelWidth
                                                                        }
                                                                        id={`select-multiple-checkbox`}
                                                                    />
                                                                }
                                                                renderValue={(
                                                                    selected
                                                                ) =>
                                                                    userType.find(
                                                                        (
                                                                            elem
                                                                        ) =>
                                                                            elem.name ===
                                                                            selected
                                                                    ) &&
                                                                    userType.find(
                                                                        (
                                                                            elem
                                                                        ) =>
                                                                            elem.name ===
                                                                            selected
                                                                    ).title
                                                                }
                                                                inputProps={{
                                                                    name:
                                                                        'type',
                                                                }}
                                                                MenuProps={{
                                                                    PaperProps: {
                                                                        style: {
                                                                            maxHeight: 250,
                                                                            width: 250,
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                {userType.map(
                                                                    (item) => (
                                                                        <MenuItem
                                                                            key={
                                                                                item.name
                                                                            }
                                                                            value={
                                                                                item.name
                                                                            }
                                                                        >
                                                                            <ListItemText
                                                                                primary={
                                                                                    item.title
                                                                                }
                                                                            />
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                            </Select>
                                                            <FormHelperText>
                                                                Select one
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    'Select group'
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                {check.length > 1 ? (
                                    <Button
                                        variant="primary"
                                        onClick={(e) => {
                                            setUpdateModal(false);
                                        }}
                                    >
                                        Ok
                                    </Button>
                                ) : (
                                    <>
                                        <Button
                                            variant="primary"
                                            onClick={(e) => {
                                                setUpdateModal(false);
                                            }}
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            className="btn-blue"
                                            disabled={isSubmitting}
                                            onClick={(e) => {
                                                handleSubmit();
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </>
                                )}
                            </Modal.Footer>
                        </form>
                    )}
                </Formik>
            </Modal>

            <ModalCreateFromCRM
                setLoader={setLoader}
                modal={modalCreateFromCRM}
                setModal={setModalCreateFromCRM}
            />

            <Portlet>
                <PortletBody>
                    <div
                        className="users-list__header-list"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Tabs
                            value={typeKey}
                            onChange={(e, value) => setTypeKey(value)}
                            indicatorColor="primary"
                            textColor="primary"
                            scrollButtons="auto"
                            variant="scrollable"
                        >
                            <Tab label="All"></Tab>
                            <Tab label="Admin"></Tab>
                            <Tab label="Supervisors"></Tab>
                            <Tab label="Staff"></Tab>
                        </Tabs>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                className="btn-blue"
                                onClick={() => {
                                    if (crm) {
                                        setDropdown(true);
                                    } else {
                                        setLogout(
                                            <Redirect to="/user-create" />
                                        );
                                    }
                                }}
                                style={{ whiteSpace: 'nowrap', marginRight: 0 }}
                            >
                                Create User
                            </Button>

                            <div
                                className="buttons-dropdown"
                                style={{ display: `${dropdown ? '' : 'none'}` }}
                                ref={buttonRef}
                            >
                                <div className="buttons-dropdown__elem">
                                    <span
                                        onClick={() =>
                                            setLogout(
                                                <Redirect to="/user-create" />
                                            )
                                        }
                                    >
                                        Create User
                                    </span>
                                </div>
                                <div className="buttons-dropdown__elem">
                                    <span
                                        onClick={() =>
                                            setModalCreateFromCRM(true)
                                        }
                                    >
                                        Create User from CRM
                                    </span>
                                </div>
                                <div
                                    className="buttons-dropdown__arrow"
                                    onClick={() => setDropdown(false)}
                                >
                                    {'>'}
                                </div>
                            </div>
                        </div>
                    </div>

                    {returnQuery('group') && (
                        <Typography variant="h5" gutterBottom>
                            Users belonging to{' '}
                            <strong>
                                {// eslint-disable-next-line
                                groupList.find(
                                    (item) => item.id == returnQuery('group')
                                ) &&
                                    // eslint-disable-next-line
                                    groupList.find(
                                        (item) =>
                                            item.id == returnQuery('group')
                                    ).name}
                            </strong>
                        </Typography>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            padding: '10px 0',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}
                        >
                            {check.length > 0 ? (
                                <>
                                    <div className="btn-toolbar">
                                        <Button
                                            variant="primary"
                                            onClick={(e) => {
                                                UserById();
                                                setUpdateModal(true);
                                            }}
                                        >
                                            Update
                                        </Button>
                                        {!(
                                            !checkMe() && user.roles !== 'admin'
                                        ) && (
                                            <Button
                                                onClick={(e) =>
                                                    setDeleteModal(true)
                                                }
                                            >
                                                Delete
                                            </Button>
                                        )}
                                    </div>

                                    {!(
                                        !checkMe() &&
                                        user.roles === 'supervisor'
                                    ) &&
                                        groupList && (
                                            <>
                                                <div className="btn-toolbar__group">
                                                    <FormControl
                                                        variant="outlined"
                                                        className="formControl"
                                                    >
                                                        <InputLabel
                                                            ref={inputLabel_2}
                                                        >
                                                            Select Groups
                                                        </InputLabel>
                                                        <Select
                                                            multiple
                                                            value={checkGroups}
                                                            name="groups"
                                                            onChange={(e) =>
                                                                setCheckGroups(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            input={
                                                                <OutlinedInput
                                                                    labelWidth={
                                                                        labelWidth_2
                                                                    }
                                                                    id={`select-multiple-checkbox`}
                                                                />
                                                            }
                                                            renderValue={(
                                                                selected
                                                            ) =>
                                                                selected
                                                                    .map(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            groupList.find(
                                                                                (
                                                                                    elem
                                                                                ) =>
                                                                                    elem.id ===
                                                                                    item
                                                                            )
                                                                                .name
                                                                    )
                                                                    .join(', ')
                                                            }
                                                            inputProps={{
                                                                name: 'groups',
                                                            }}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: 250,
                                                                        width: 250,
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {groupList.map(
                                                                (item) => (
                                                                    <MenuItem
                                                                        key={
                                                                            item.id
                                                                        }
                                                                        value={
                                                                            item.id
                                                                        }
                                                                    >
                                                                        <ListItemText
                                                                            primary={
                                                                                item.name
                                                                            }
                                                                        />
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>

                                                    {/* <Button onClick={addGroups} className="btn-blue"> */}
                                                    <Button
                                                        onClick={() =>
                                                            checkGroups.length !==
                                                            0
                                                                ? addGroups()
                                                                : setConfirmAddModal(
                                                                      true
                                                                  )
                                                        }
                                                        className="btn-blue"
                                                    >
                                                        Save
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                </>
                            ) : (
                                <>
                                    <Button variant="info" onClick={checkAll}>
                                        Edit All
                                    </Button>
                                    {groupList && (
                                        <FormControl
                                            variant="outlined"
                                            className="formControl"
                                        >
                                            <InputLabel ref={inputLabel_4}>
                                                Group
                                            </InputLabel>
                                            <Select
                                                value={groupListData}
                                                input={
                                                    <OutlinedInput
                                                        labelWidth={
                                                            labelWidth_4
                                                        }
                                                    />
                                                }
                                                onChange={(e) => {
                                                    setGroupListData(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <MenuItem value={''}>
                                                    All user
                                                </MenuItem>
                                                <MenuItem
                                                    value={'all_group_dev'}
                                                >
                                                    (All group)
                                                </MenuItem>
                                                {groupList.map((item) => (
                                                    <MenuItem
                                                        key={item.id}
                                                        value={parseInt(
                                                            item.id
                                                        )}
                                                    >
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </>
                            )}
                        </div>

                        <div className={`search-wrap`}>
                            <TextField
                                key={'search'}
                                className="formControl"
                                label="Search"
                                margin="normal"
                                variant="outlined"
                                value={search}
                                style={{ marginLeft: 0 }}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        setSearch(e.target.value);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <TableList
                        data={filterData}
                        check={check}
                        setCheck={setCheck}
                        sort={sort}
                        setSort={setSort}
                        plan={plan}
                    />
                    <div className="pagination-wrap">
                        <Pagination>
                            <Pagination.First
                                onClick={() => setPagination(1)}
                                disabled={pagination === 1}
                            />
                            <Pagination.Prev
                                onClick={() => setPagination((p) => p - 1)}
                                disabled={pagination === 1}
                            />

                            <Pagination.Item>{pagination}</Pagination.Item>

                            <Pagination.Next
                                onClick={() => setPagination((p) => p + 1)}
                                disabled={pagination === page.last_page}
                            />
                            <Pagination.Last
                                onClick={() => setPagination(page.last_page)}
                                disabled={pagination === page.last_page}
                            />
                        </Pagination>
                        <div className="pagination-show">
                            <FormControl
                                variant="outlined"
                                className="formControl"
                            >
                                <InputLabel ref={inputLabel_3}>
                                    Show Entries
                                </InputLabel>
                                <Select
                                    value={show}
                                    input={
                                        <OutlinedInput
                                            labelWidth={labelWidth_3}
                                        />
                                    }
                                    onChange={(e) => {
                                        setShow(e.target.value);
                                    }}
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </Select>
                            </FormControl>
                            <Typography variant="body1" gutterBottom>
                                {page &&
                                    `Showing ${(pagination - 1) *
                                        page.per_page +
                                        (page.total ? 1 : 0)} - ${
                                        pagination * page.per_page > page.total
                                            ? page.total
                                            : pagination * page.per_page
                                    } fo ${page.total}`}
                            </Typography>
                        </div>
                    </div>
                </PortletBody>
            </Portlet>
        </StyledGroupLists>
    );
};

const StyledGroupLists = styled.div`
    .MuiTab-wrapper {
        font-size: 12px;
        font-weight: 700;
    }

    .btn {
        margin-right: 15px;
    }

    .btn-toolbar {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;

        &__group {
            display: flex;
            align-items: center;

            .formControl {
                margin-left: 0;
            }
        }
    }

    .edit-all {
        height: 100%;
        border-radius: 4px;
        background-color: #eef1ff;
        padding: 0 12px;
        font-weight: 600;
        font-size: 13px;
        line-height: 19px;
        color: #5d78ff;
        display: flex;
        align-items: center;
    }

    .pagination {
        margin: 0;

        @media (max-width: 600px) {
            margin: 10px auto;
        }

        &-wrap {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 10px;

            .MuiSelect-select {
                padding: 7px;
            }
        }

        &-show {
            display: flex;
            align-items: center;
            min-width: 290px;
        }
    }

    .formControl {
        width: 150px;
        margin: 5px 10px;
    }
`;
